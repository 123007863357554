import axios from "axios";
import config from "../../public/config.json"

const ApiOmniwallet = axios.create({
    //baseURL: config.api.url,
    withCredentials: true,
    timeout: 30000,

});

ApiOmniwallet.interceptors.request.use(function (config) {
    const hostname = window.location.hostname;
    config.baseURL = `https://api.${hostname}`;

    // For local testing (TODO: should check node env prod or dev instead)
    //const hostname = 'tenant.base_omniwallet.test';
    //config.baseURL = `https://${hostname}`;

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export default ApiOmniwallet;
